<template>
  <div ref="mapRef" class="map" data-test="map">
  </div>
</template>

<script>
// TODO: ENABLE LINTER AND FOLLOW STYLEGUIDE...
/* eslint-disable */
import { mapState } from "vuex"
// import gMapsInit from '@/helpers/gmaps'
// import gMapsStyles from '@/helpers/gmaps_styles'
import Actions from '@/store/actions'
import { Loader } from "@googlemaps/js-api-loader"

export default {
  name: "WaypointsMap",
  props: {
    isMapActive: {
      type: Boolean,
      default: true
    },
    zoomDragEnabled: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      map: false,
      google: null,
      path: false,
      busyAdding: false,
      defaultZoom: 10,
      markers: {},
    };
  },
  async mounted() {
    try {
      // this.google = await gMapsInit();
      const API_KEY = 'AIzaSyA1hQ74YrsRsszqbMbYgkQGU4bKA31D_BE'

      const loader = new Loader({
        apiKey: API_KEY,
        version: "weekly",
      })
      await loader.load()

      const { Map } = await google.maps.importLibrary("maps");

      const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker")
      this.googleElements = { AdvancedMarkerElement, PinElement }

      this.map = new Map(this.$refs.mapRef, {
        // styles: gMapsStyles,
        mapId: '74dca0433cfa66b1',
        center: {
           lat: 43.293461,
           lng: 6.646186
        },
        zoom: this.defaultZoom,
        disableDefaultUI: true,
        scrollwheel: this.zoomDragEnabled,
        disableDoubleClickZoom: false,
        draggable: this.zoomDragEnabled,
        zoomControl: this.zoomDragEnabled,
        fullscreenControl: this.zoomDragEnabled
      })

      this.path = new google.maps.Polyline({
          path: [],
          geodesic: true,
          strokeColor: "#000000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          map: this.map
      })

      this.setMarkers()
      this.centerAndFit()

      this.map.addListener('click', this.onMapClick);
      this.map.addListener('dblclick', this.onMapDblClick);
    } catch (error) {
      console.error(error);
    }
  },
  created: function() {

    if(this.waypoints.length) {
      this.ensureMapIsSet().
        then(() => {
          this.centerAndFit()
          if (this.zoomDragEnabled && this.waypoints.length > 0) {
            this.$store.dispatch(Actions.SET_SELECTED_WAYPOINT, this.waypoints[this.waypoints.length - 1])
          } else {
            this.$store.dispatch(Actions.SET_SELECTED_WAYPOINT, false)
          }
          this.setMarkers()
        })
    }
  },
  computed: {
    ...mapState({
      waypoints: state => state.waypoint.waypoints,
      selectedWaypoint: state => state.waypoint.selectedWaypoint,
      loading: state => state.waypoint.loading
    }),
  },

  methods: {
    setMarkers() {
      var addingMarker = (Object.keys(this.markers).length > 0)

      for (const [index, wp] of this.waypoints.entries()) {
        if (this.markers[wp.id]) {
          this.markers[wp.id].position ={lat: wp.latitude, lng: wp.longitude}
          // this.markers[wp.id].setIcon(this.icon(wp, index))
          this.markers[wp.id].content = this.icon(wp, index)
          this.markers[wp.id].wp = wp
        } else {
          if (addingMarker && wp.is_temp)
            var anim = google.maps.Animation.DROP
          else
            var anim = null

          var marker = new this.googleElements.AdvancedMarkerElement({
            position: {lat: wp.latitude, lng: wp.longitude},
            map: this.map,
            gmpDraggable: this.isMapActive && !wp.is_temp,
            // icon: this.icon(wp, index),
            content: this.icon(wp, index),
            // crossOnDrag: false,
            // wp: wp,
            // animation: anim
          })
          marker.wp = wp
          if(this.isMapActive && !wp.is_temp) {
            marker.addListener('dragstart', (e) => this.onDragStart(wp.id, e));
            marker.addListener('dragend', (e) => this.onDragEnd(wp.id, e));
            marker.addListener('drag', (e) => this.onDrag(wp.id, e));
          }
          if(this.isMapActive || this.zoomDragEnabled) {
            marker.addListener('click', (e) => this.onMarkerClick(wp.id, e));
          }
          this.markers[wp.id]= marker
        }
      }

      var wp_ids = this.waypoints.map((wp) => wp.id)
      for (var wp_id of Object.keys(this.markers)) {
        if(! wp_ids.includes(parseInt(wp_id))) {
          this.markers[wp_id].setMap(null)
          delete(this.markers[wp_id])
        }
      }

      this.path.setPath(this.waypoints.map((wp) => {
        return {lat: wp.latitude, lng: wp.longitude}
      }))
    },
    onMapDblClick(event) {
      this.is_click = false
   },
    onMapClick(event) {
      if (!this.isMapActive) return;
      this.is_click = true
      var update_timeout = setTimeout(function(){
        if (this.is_click && !this.busyAdding) {
          this.busyAdding = true
          let latitude = event.latLng.lat();
          let longitude = event.latLng.lng();
          this.$store.dispatch(Actions.CREATE_WAYPOINT, { latitude, longitude })
            .then(() => { this.busyAdding = false })

          let bounds = this.map.getBounds()
          let ne = bounds.getNorthEast()
          let sw = bounds.getSouthWest()
          let x = Math.abs(event.latLng.lng() - sw.lng())
          let dx = Math.abs(ne.lng() - sw.lng())
          let y = Math.abs(event.latLng.lat() - ne.lat())
          let dy = Math.abs(ne.lat() - sw.lat())
          if(x < 0.15 * dx || x > 0.85 * dx || y < 0.15 * dy || y > 0.85 * dy) {
            this.map.panTo(event.latLng)
          }
        }
      }.bind(this), 80);
    },
    onMarkerClick(wpId, e) {
      var wp = this.markers[wpId].wp
      if (wp) {
        this.$store.dispatch(Actions.SET_SELECTED_WAYPOINT, wp)
        this.setMarkers()
      } else {
        this.onMapClick(e);
      }
    },
    onDragStart(wpId, $event) {
      var marker = this.markers[wpId]
      this.$store.dispatch(Actions.SET_SELECTED_WAYPOINT, marker.wp)
      // marker.setIcon('https://maps.gstatic.com/mapfiles/ridefinder-images/mm_20_blue.png')
    },
    onDrag(curWpId, $event) {
      this.path.setPath(this.waypoints.map((wp) => {
        if (wp.id == curWpId)
          return $event.latLng
        else
          return {lat: wp.latitude, lng: wp.longitude}
      }))
    },
    onDragEnd(wpId, $event) {
      this.$store.dispatch(Actions.SAVE_WAYPOINT, {
        waypointId: wpId,
        waypointData: Object.assign({
          latitude: $event.latLng.lat(),
          longitude: $event.latLng.lng()
        }),
        commitTemp: true
      })
      this.setMarkers()
    },
    centerAndFit() {
      if (this.waypoints.length === 0)
         return
       if (this.waypoints.length === 1) {
           var newCenter = {
              lat: this.waypoints[0].latitude,
              lng: this.waypoints[0].longitude
           };
           this.map.panTo(newCenter);
        } else {
          // this.map.resizePreserveCenter();
          const oldCenter = this.map.getCenter();
          let bounds = new window.google.maps.LatLngBounds();
          this.waypoints.forEach(marker => {
           bounds.extend(
             new window.google.maps.LatLng(marker.latitude, marker.longitude)
           );
          });
          this.map.fitBounds(bounds);
          window.google.maps.event.trigger(this.map, 'resize');
          this.map.setCenter(oldCenter);
       }
    },
    ensureMapIsSet() {
      var that = this
      return new Promise(function (resolve, reject) {
        (function waitForMap(){
            if (that && that.map) return resolve()
            setTimeout(waitForMap, 30)
        })()
      })
    },
    icon(wp, index, dragging = false) {
      const markerColor = {
        default: '#00000088',
        first: '#35C220FF',
        isCall: '#2D7F28FF',
        current: '#3C98D9FF',
        last: '#CF2F20FF',
        temp: '#33333333'
      }

      let strokeColor = markerColor.default
      let color = markerColor.default

      if (wp.is_temp) {
        color = markerColor.temp
        strokeColor = markerColor.temp
      }
      else if (this.selectedWaypoint && wp && this.selectedWaypoint.id === wp.id) {
        color = markerColor.current
        strokeColor = markerColor.current
      }
      else if (index === 0) {
        color = markerColor.first
      }
      else if (index === this.waypoints.length-1) {
        color = markerColor.last
      }
      else if (wp.is_call) {
        color = markerColor.isCall
      }

      // return new this.googleElements.PinElement ({
      //   // path: window.google.maps.SymbolPath.CIRCLE,
      //   scale: this.defaultZoom > 6 ? 1 : .1,
      //   background: color,
      //   // fillOpacity: 1,
      //   borderColor: strokeColor,
      //   glyphColor: strokeColor,
      //   // strokeOpacity: 1.0,
      //   // strokeWeight: 2
      // })
      
      const content = document.createElement("div")
      content.style.width = `${this.defaultZoom > 6 ? 15 : 10}px`
      content.style.height = `${this.defaultZoom > 6 ? 15 : 10}px`
      content.style.borderRadius = "50%"
      content.style.backgroundColor = color
      content.style.border = `2px solid ${strokeColor}`
      content.style.transform = 'translate(0, 50%)'
      
      return content
    }
  },

  watch: {
    loadingWaypoints (newVal, oldVal) {
      if ((oldVal == true) && (newVal == false)) {
        this.ensureMapIsSet().
          then(() => {
            this.centerAndFit()
            if (this.zoomDragEnabled && this.waypoints.length > 0) {
              this.$store.dispatch(Actions.SET_SELECTED_WAYPOINT, this.waypoints[this.waypoints.length - 1])
            } else {
              this.$store.dispatch(Actions.SET_SELECTED_WAYPOINT, false)
            }
            this.setMarkers()
          })
      }
    },
    waypoints (newVal, oldVal) {
      this.ensureMapIsSet()
        .then(this.setMarkers)
    },
    selectedWaypoint (newVal, oldVal) {
      this.ensureMapIsSet().
        then(() => { this.setMarkers() })
    }
  }
}
</script>
<style lang="scss">
.map {
  width: 100%;
  height: 100%;
  min-height: 456px;
  @include mobile {
    min-height: 356px;
  }
}

.infobar {
  padding: 10px;
}
</style>
